import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {IntlProvider} from "react-intl";
import messages_pl from "./translations/pl.json";
import messages_en from "./translations/en.json";
import DocumentMeta from 'react-document-meta';

/**
 * Language mapper
 */
const messages = {
  'pl': messages_pl,
  'en': messages_en
};

/**
 * Used locale on the site
 * @type {string}
 */
const language = getLanguage();

/**
 * Get language: first check cookie and if it's empty get language from browser
 */
function getLanguage() {
  const value = "; " + document.cookie;
  const parts = value.split("; language=");
  const lang = parts.length === 2 ? parts.pop().split(";").shift() : navigator.language.split(/[-_]/)[0];

  return ['en', 'pl'].includes(lang) ? lang : 'en';
}

const metaDataTranslations = {
  en: {
    title: 'Fitboar.com',
    description: 'Record your workouts, track your progress and share it with your friends. Join today!',
    keywords: 'gym, shredded, training, fit, personal trainer'
  },
  pl: {
    title: 'Fitboar.com',
    description: 'Notuj treningi, śledź postępy i dziel się nimi ze znajomymi. Dołącz już dziś!',
    keywords: 'siłownia, trening, fit, trener personalny',
  }
};

const metaData = {
  title: metaDataTranslations[getLanguage()].title,
  description: metaDataTranslations[getLanguage()].description,
  meta: {
    charset: 'utf-8',
    name: {
      keywords: metaDataTranslations[getLanguage()].keywords
    }
  }
};

ReactDOM.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <DocumentMeta {...metaData}>
      <App/>
    </DocumentMeta>
  </IntlProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
