import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from 'react-intl';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: this.getCookie('language'),
    };
  }

  /**
   * Generate uri depending on which sub page customer is currently on
   *
   * @param hashParam
   * @returns {string}
   */
  getHashUri(hashParam) {
    return window.location.pathname !== '/' ? `/#${hashParam}` : `#${hashParam}`;
  }

  /**
   * Find and display language switcher container
   */
  openLanguageSwitcher() {
    const languageSwitcher = document.getElementById('language-switcher');

    if (languageSwitcher) {
      languageSwitcher.classList.remove('hidden');
    }

    const selectedLanguageFlag = document.getElementById('selected-language');

    if (selectedLanguageFlag) {
      selectedLanguageFlag.classList.add('hidden');
    }
  }

  /**
   * Find and display language switcher container
   */
  scrollTop() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  }

  /**
   * Find and hide language switcher container
   */
  closeLanguageSwitcher() {
    const languageSwitcher = document.getElementById('language-switcher');

    if (languageSwitcher) {
      languageSwitcher.classList.add('hidden');
    }

    const selectedLanguageFlag = document.getElementById('selected-language');

    if (selectedLanguageFlag) {
      selectedLanguageFlag.classList.remove('hidden');
    }
  }

  /**
   * Select language and set it to state and cookie
   * @param language
   */
  selectLanguage(language) {
    this.setState(
      {language: language},
      this.changeCookieLanguage
    )
  }

  /**
   * Set language in cookie
   */
  changeCookieLanguage() {
    this.setCookie('language', this.state.language);
    this.closeLanguageSwitcher();
    window.location.reload();
  }

  /**
   * Get cookie by name
   */
  getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    return parts.length === 2 ? parts.pop().split(";").shift() : navigator.language.split(/[-_]/)[0];
  }

  /**
   * Set cookie by name
   */
  setCookie(name,value) {
    document.cookie = name + "=" + (value || "") + "; path=/";
  }

  render() {
    return (
      <React.Fragment>
        <header className="header">
          <nav
            className={"navbar navbar-expand-lg fixed-top " + (this.props.bgColor && this.props.bgColor === 'white' ? 'custom-nav white-bg' : 'bg-transparent')}>
            <div className="container">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ti-menu"></span>
              </button>

              <div className="header-logo-section">
                <img src="/img/logo.png" alt="logo" width="40px" className="logo" onClick={() => {
                  this.scrollTop()
                }}/>
                <img src="/img/logo-text.png" alt="logo" height="30px" onClick={() => {
                  this.scrollTop()
                }}/>
              </div>

              <div
                className="collapse navbar-collapse main-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href={this.getHashUri('download')}>
                      <FormattedMessage id="navigation.gotoapp"/>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href='https://fitboar.com/sign-up'>
                      <FormattedMessage id="navigation.register"/>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href={this.getHashUri('features')}>
                      <FormattedMessage id="navigation.features"/>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href={this.getHashUri('pricing')}>
                      <FormattedMessage id="navigation.pricing"/>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href={this.getHashUri('screenshots')}>
                      <FormattedMessage id="navigation.gallery"/>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href={this.getHashUri('contact')}>
                      <FormattedMessage id="navigation.contact"/>
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <ul>
                  <li className="nav-item">
                    <div className="language-switcher-container">
                      <div id="selected-language">
                        {this.state.language === 'pl' && <img src="/img/flag/pl.png" alt="flag-pl" width="40px" onClick={() => {
                          this.openLanguageSwitcher()
                        }}/>}
                        {this.state.language === 'en' && <img src="/img/flag/en.png" alt="flag-en" width="40px" onClick={() => {
                          this.openLanguageSwitcher()
                        }}/>}
                      </div>

                      <div className="language-switcher hidden" id="language-switcher">
                        <ul>
                          <li onClick={() => {
                            this.selectLanguage('pl')
                          }}>
                            <img src="/img/flag/pl.png" alt="flag-pl" width="40px"/>
                          </li>
                          <li onClick={() => {
                            this.selectLanguage('en')
                          }}>
                            <img src="/img/flag/en.png" alt="flag-pl" width="40px"/>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(Header);
