import React, {Component} from "react";
import _data from "../../data";

class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: {},
      language: this.getCookie('language'),
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      testimonial: _data.testimonial
    });
  }

  /**
   * Get cookie by name
   */
  getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    return parts.length === 2 ? parts.pop().split(";").shift() : navigator.language.split(/[-_]/)[0];
  }

  render() {
    return (
      <React.Fragment>
        <section
          className={"testimonial-section ptb-100 " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : '')}>
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                {this.state.language === 'pl' && <div className="section-heading mb-5">
                  <h1>
                    Regulamin serwisu fitboar.com
                  </h1>

                  <ol>
                    <li>
                      <h4>Postanowienia wstępne</h4>
                      <ol>
                        <li>Niniejszy regulamin określa zasady, na jakich użytkownicy Internetu mogą korzystać z serwisu
                          fitboar.com.
                        </li>
                        <li>Treści dostępne w serwisie fitboar.com dostępne są tylko dla zalogowanych użytkowników.</li>
                        <li>Warunkiem uzyskania dostępu do funkcjonalności serwisu fitboar.com jest założenie konta
                          użytkownika oraz korzystanie z urządzenia komunikującego się z Internetem i wyposażonego w
                          przeglądarkę internetową.
                        </li>
                        <li>Serwis fitboar.com umożliwia przechowywanie danych przez użytkowników. Serwis fitboar.com
                          nie pośredniczy w wyborze danych ani nie modyfikuje informacji zawartych w przekazie
                          jednocześnie zastrzega sobie możliwość ich usunięcia, gdy nie będą zgodne z regulaminem
                          serwisu.
                        </li>
                        <li>Wykonywanie ćwiczeń zawartych w serwisie nie gwarantuje osiągnięcia zamierzonych efektów.
                          Służą one tylko jako element pomocniczy przy budowaniu treningów. Fitboar.com nie ponosi
                          odpowiedzialności za błędną interpretacje tych ćwiczeń, a co za tym idzie nie
                          satysfakcjonujących efektów oraz ewentualnych kontuzji.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Definicje</h4>
                      <ol>
                        <li>Regulamin - niniejszy regulamin.</li>
                        <li>Serwis fitboar.com - internetowy serwis prowadzony w domenie fitboar.com przez firmę IT
                          SERVICES KRYSTIAN JARMOSZKA.
                        </li>
                        <li>Firma - IT SERVICES KRYSTIAN JARMOSZKA Al. Armii Krajowej 6B/6 50-541 Wrocław. NIP:
                          7471860332.
                        </li>
                        <li>Konto - zbiór zasobów i uprawnień w serwisie fitboar.com.</li>
                        <li>Użytkownik - osoba zarejestrowana w serwisie fitboar.com.</li>
                        <li>Tablica - zbiór postów stworzonych przez użytkownika.</li>
                        <li>Ćwiczenia - sugerowana lista ćwiczeń, która ma służyć jako wzór dla użytkownika. Serwis
                          fitboar.com nie gwarantuje poprawności w nazewnictwie ćwiczeń oraz kategoryzacji
                          poszczególnych parametrów, takich jak: grupy mięśniowe, typy oraz opcje.
                        </li>
                        <li>Trening - zbiór ćwiczeń z możliwością ustawienia dodatkowych opcji oraz logowania rezultatów
                          w grupach.
                        </li>
                        <li>Pomiary - zapisywanie pomiarów ciała i analizowanie ich zmian na przestrzeni czasu.</li>
                      </ol>
                    </li>
                    <li>
                      <h4>Rejestracja w serwisie fitboar.com</h4>
                      <ol>
                        <li>Zarejestrować się w serwisie fitboar.com mogą tylko osoby fizyczne, które ukończyły 13 lat
                          (trzynaście lat). Użytkownikami nie mogą być przedsiębiorcy, chyba że serwis wyrazi na to
                          zgodę.
                        </li>
                        <li>W celu dokonania rejestracji należy skorzystać z formularza, który dostępny jest w serwisie
                          fitboar.com.
                        </li>
                        <li>Po udanej rejestracji użytkownik otrzyma dostęp do własnego konta w serwisie fitboar.com.
                          Użytkownik jest odpowiedzialny za informacje opublikowane na własnym koncie przez co ma prawo
                          do zarządzania kontem oraz jego usunięcia.
                        </li>
                        <li>W momencie udanej rejestracji dochodzi do zawarcia pomiędzy użytkownikiem a serwisem
                          fitboar.com umowy o świadczenie usług drogą elektroniczną na warunkach określonych w
                          Regulaminie.
                        </li>
                        <li>Użytkownicy, którzy mieli w przeszłości zablokowane konto z powodu naruszenia prawa lub
                          regulaminu, nie mają prawa do ponownej rejestracji w serwisie fitboar.com.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Zasady korzystania z serwisu fitboar.com</h4>
                      <ol>
                        <li>Użytkownik nie może postępować w sposób niezgodny z prawem oraz ogólnie przyjętymi zasadami
                          moralnymi.
                        </li>
                        <li>Użytkownik ponosi pełną odpowiedzialność za treści, które udostępnia w serwisie
                          fitbboar.com
                        </li>
                        <li>Użytkownik nie może podszywać się pod inne osoby.</li>
                        <li>Zakazane są działania zakłócające lub mające na celu zniszczenie elementów serwisu.</li>
                        <li>Nie można tworzyć kont w sposób zautomatyzowany oraz używania serwisu w sposób inny niż
                          oferowany przez serwis fitboar.com.
                        </li>
                        <li>
                          Zakazane jest udostępnianie treści niezgodnych z prawem oraz ogólnie przyjętymi zasadami
                          moralnymi i współżycia w społeczeństwie. Zawierają się w nich między innymi treści
                          zawierające:<br />
                          - wypowiedzi wulgarne oraz obraźliwe<br />
                          - przemoc oraz pornografię<br />
                          - dyskryminację ze względu na rasę, kolor skóry, płeć, wyznanie religijne, orientację<br />
                          seksualną, narodowość lub inne mogące urazić inną osobę
                          - naruszające prawa autorskie osób trzecich
                        </li>
                        <li> Zakazane jest udostępnianie odnośników do stron internetowych zawierających treści opisane
                          w 4.6.
                        </li>
                        <li>Nie można podejmować prób sprzedaży konta.</li>
                        <li>Zakazane jest używanie serwisu fitboar.com przez osoby skazane za przestępstwa seksualne.
                        </li>
                        <li>Użytkownik umieszczając dane w serwisie fitboar.com wyraża zgodę na wykorzystywanie ich w
                          celu prowadzenia serwisu.
                        </li>
                        <li>Użytkownik może umieszczać tylko dane, do których ma stosowne prawa.</li>
                        <li>Serwis fitboar.com oferuje swoje funkcjonalności tylko na terenie Polski.</li>
                      </ol>
                    </li>
                    <li>
                      <h4>Rola operatora serwisu fitboar.com</h4>
                      <ol>
                        <li>Jeżeli użytkownik wybierze nazwę, która będzie zawierać treści opisane w punkcie 4.6. możemy
                          tę nazwę zmienić.
                        </li>
                        <li>Jeżeli treści udostępniane przez użytkownika zawierają się w punkcie 4.6 mogą zostać one
                          przez nas zmienione lub usunięte a w uzasadnionych przypadkach zablokować lub usunąć
                          użytkownika publikującego ów treści.
                        </li>
                        <li>Serwis Fitboar.com dokłada należytych starań by zapewnić prawidłowe funkcjonowanie serwisu.
                          Nie mniej jednak istnieje możliwość ograniczenia dostępu do funkcjonalności w celu wykonania
                          niezbędnych prac serwisowych.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Reklamacje i roszczenia</h4>
                      <ol>
                        <li>Użytkownik może zgłaszać reklamacje dotyczące opisanego w regulaminie funkcjonowania serwisu
                          fitboar.com.
                        </li>
                        <li>Reklamacje można zgłaszać pisemnie, w formie listu poleconego, na adres: Al. Armii Krajowej
                          6B/6 50-541 Wrocław, bądź poprzez wysłanie wiadomości na adres e-mail: info@fitboar.com
                        </li>
                        <li>Reklamacja powinna zawierać nazwę użytkownika, adres email użyty podczas rejestracji,
                          dokładny opis powodu reklamacji.
                        </li>
                        <li>Reklamacje zostaną rozpatrzone w terminie 14 dni od daty otrzymania przez serwis fitboar.pl
                          prawidłowo złożonej reklamacji.
                        </li>
                        <li>Serwis fitboar.pl zobowiązuje się wysłać informacje o sposobie rozpatrzenia reklamacji na
                          adres e-mail przypisany do konta użytkownika.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Zakończenie korzystania z serwisu fitboar.com</h4>
                      <ol>
                        <li>Świadczenie usług w ramach serwisu fitboar.com ma charakter bezterminowy, jednakże
                          Użytkownik może zamknąć konto w każdym momencie korzystając z funkcjonalności zamknięcia
                          konta, która jest dostępna w serwisie fitboar.com.
                        </li>
                        <li>Zamkniecie konta w serwisie fitboar.com jest jednoznaczne z rozwiązaniem umowy.</li>
                        <li>
                          Serwis fitboar.com może zamknąć lub zablokować konto użytkownika w trybie natychmiastowym lub
                          usunąć jakiekolwiek treści udostępnione przez użytkownika gdy:
                          <ol>
                            <li>Użytkownik narusza postanowienia regulaminu, bądź zaakceptowanych przez użytkownika
                              odrębnych innych regulaminów.
                            </li>
                            <li>Użytkownik podał dane lub złożył oświadczenia nieprawdziwe, nieaktualne, nieprawidłowe
                              bądź niepełne lub podał dane innych osób.
                            </li>
                            <li>Gdy ciąży na nas taki obowiązek prawny.</li>
                          </ol>
                        </li>
                        <li>Jeżeli użytkownik sądzi, że jego konto zostało zablokowane lub usunięte przez pomyłkę lub
                          chce wyłączyć lub całkowicie usunąć konto, powinien zgłosić reklamację do serwisu fitboar.com.
                        </li>
                        <li>W innych niż wskazane w powyższym punkcie 7.3 sytuacjach serwis fitboar.com uprawniony jest
                          do rozwiązania umowy z zachowaniem 14-dniowego okresu wypowiedzenia.
                        </li>
                        <li>Po wykonaniu polecenia usunięcia konta uruchamiana jest procedura, która w ciągu
                          najbliższych 24 godzin usunie wszystkie zdjęcia zamieszczone przez użytkownika oraz wszelkie
                          jego dane osobowe pozostawiając jedynie obiekty, które stworzył takie jak posty, komentarze,
                          polubienia, które będą podmienione na stały tekst informujący o tym, że ów treści obiektów
                          zostały usunięte. Dane te zostaną podane Anonimizacji co uniemożliwi identyfikacje
                          użytkownika. . Zamknięcie konta opisane jest pod adresem: https://fitboar.com/#!/app/contact.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Dane osobowe i ochrona prywatności w serwisie fitboar.com</h4>
                      <ol>
                        <li>Serwis fitboar.com jest administratorem danych osobowych użytkownika niezbędnych dla
                          realizacji postanowień regulaminu. Dane te są przetwarzane przez serwis fitboar.com dla
                          zawarcia i wykonania umowy na warunkach określonych w regulaminie, w tym zapewnienia należytej
                          jakości usług.
                        </li>
                        <li>Serwis fitboar.com przetwarza dane osobowe użytkowników również w innych celach i zakresie,
                          szczegółowo wskazanych w Polityce Prywatności znajdującej się <a
                            href="https://fitboar.com/privacy-policy">tutaj</a>.
                        </li>
                        <li>W zakresie nieuregulowanym niniejszym regulaminem zasady przetwarzania danych osobowych oraz
                          ochrony prywatności użytkowników określa dokument „Polityka prywatności” – dostępny <a
                            href="https://fitboar.com/privacy-policy">tutaj</a>. Dokument ten zawiera pełne informacje
                          wymagane przez RODO. Użytkownik zobowiązany jest szczegółowo zapoznać się z zawartością
                          dokumentu oraz zaakceptować jego warunki przed rozpoczęciem korzystania z serwisu.
                        </li>
                        <li>Serwis fitboar.com przetwarza dane zgodnie z przepisami prawa, w tym w szczególności z
                          Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w
                          sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
                          swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (zwanym dalej „RODO”).
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Aktualizacja regulaminu</h4>
                      <ol>
                        <li>W przypadku zmiany regulaminu fitboar.com poinformuje o tym użytkowników w serwisie lub
                          drogą mailową. Jeżeli użytkownik nie będzie się zgadzał ze zmianami w regulaminie może usunąć
                          konto. Jak to zrobić można znaleźć tutaj https://fitboar.com/#!/app/contact
                        </li>
                        <li>Korzystanie z serwisu po zapoznaniu się z nowym regulaminem oznacza, że użytkownik
                          zaakceptował jego nową formę.
                        </li>
                        <li>Powiadomimy użytkowników o zmianach w regulaminie na co najmniej 14 dni przed ich
                          wprowadzeniem dając mu tym samym czas na zapoznaniem się z nim.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>}

                {this.state.language === 'en' && <div className="section-heading mb-5">
                  <h1>Terms and Conditions</h1>

                  <ol className="counter-reset: item">
                    <li>
                      <h4>Preliminary provisions</h4>
                      <ol>
                        <li>This document determines the conditions to which the users of the Fitboar.com Service are
                          bound to observe.
                        </li>
                        <li>Contents of the Fitboar.com Service are available only to logged in users.</li>
                        <li>Features of the Fitboar.com Service become available upon creating a user account and
                          require a device with network access and an internet browser.
                        </li>
                        <li>The Fitboar.com Service allows storage of user’s personal information. The Fitboar.com
                          Service does not administer nor modify the information provided. Further, it reserves the
                          right to delete any information that does not comply with the Terms of Service.
                        </li>
                        <li>It is not guaranteed that performing the exercises listed in the Service will provide
                          intended results. They are solely intended for reference when creating workout routines. The
                          Fitboar.com Service may not be held accountable for misinterpretation of these exercises,
                          which may in consequence result in injury or unsatisfactory performance.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Definitions</h4>
                      <ol>
                        <li>Terms of Service – terms contained herein.</li>
                        <li>Fitboar.com Service – internet service operated on fitboar.com domain by company IT SERVICES
                          KRYSTIAN JARMOSZKA
                        </li>
                        <li>Company – IT SERVICES KRYSTIAN JARMOSZKA Armii Krajowej 6B/6 50-541 Wrocław. Tax ID:
                          7471860332.
                        </li>
                        <li>Account – holds the information and access privileges on the Fitboar.com Service.</li>
                        <li>User – a person registered on the Fitboar.com Service.</li>
                        <li>Feed – a collection of posts submitted by the User.</li>
                        <li>Exercises – proposed list of exercises which the User can refer to. The Fitboar.com Service
                          does not guarantee the accuracy nor correctness of exercise terminology or categorization of
                          specific parameters, such as muscle groups, types or available options.
                        </li>
                        <li>Training – a set of exercises which can be adjusted ond allow saving of results in groups.</li>
                        <li>Measurement – note body measurements and analyse them over time.</li>
                      </ol>
                    </li>
                    <li>
                      <h4>Registration in the Fitboar.com Service</h4>
                      <ol>
                        <li>Only natural persons above the age of 13 are allowed to register in the Fitboar.com Service.
                          Entrepreneurs may only register as Users upon Service's approval.
                        </li>
                        <li>User account may be created by using a registration form available in the Fitboar.com
                          Service.
                        </li>
                        <li>Following a successful registration, the User will be granted access to their own account in
                          the Fitboar.com Service. The User is responsible for all information submitted on their
                          account and is therefore eligible to manage or delete it.
                        </li>
                        <li>A successful registration of an account bounds the User and the Fitboar.com Service with an
                          online service agreement, regulated by the Terms of Service.
                        </li>
                        <li>Users who had their Fitboar.com account locked in the past due to infringement of law or the
                          Terms of service are not eligible to register a new account in the Fitboar.com Service.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Terms of use of the Fitboar.com Service</h4>
                      <ol>
                        <li>The User may not act unlawfully or against commonly accepted moral principles.</li>
                        <li>The User is fully liable of contents that they submit on the Fitboar.com Service.</li>
                        <li>The User may not impersonate other natural persons.</li>
                        <li>Any actions intended to disrupt or damage the integrity of the Service are forbidden.</li>
                        <li>It is not allowed to register new accounts through an automated process or to use the
                          Service for other purposes than it is intended.
                        </li>
                        <li>
                          It is not allowed to submit any contents that are illegal or against commonly accepted moral
                          or social principles. This includes contents such as:<br/>
                          - vulgar and offensive comments<br/>
                          - violence and pornography<br/>
                          - discrimination based on race, colour of skin, gender, religion, sexual orientation,
                          nationality or other categories that could be harmful<br/>
                          - third party copyright infringement
                        </li>
                        <li>It is not allowed to share links to websites which include contents listed in point 4.6.
                        </li>
                        <li>It is not allowed sell the account.</li>
                        <li>Sex offenders are forbidden from using the Fitboar.com Service.</li>
                        <li>By submitting content on the Fitboar.com Service the User gives their consent that it will
                          be used to operate the Service.
                        </li>
                        <li>User may only submit contents to which they own the right.</li>
                        <li>The Fitboar.com Service is only available to people within the territory of Poland.</li>
                      </ol>
                    </li>
                    <li>
                      <h4>Role of the Fitboar.com Service Administrator</h4>
                      <ol>
                        <li>If User creates a name which includes contents listed in point 4.6, the Administrator
                          reserves the right to adjust it.
                        </li>
                        <li>If information submitted by the User include contents listed in point 4.6, it may be
                          adjusted or removed by the Administrator. In severe cases the User's account may be locked or
                          removed.
                        </li>
                        <li>The Fitboar.com Service exercises due diligence to maintain correct operation of the
                          Service. However, a temporary limitation of the Service's operation may occur to conduct
                          necessary maintenance.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Claims and complaints</h4>
                      <ol>
                        <li>User can submit complaints about the operation of the Fitboar.com Service that is explained
                          in the Terms of Service.
                        </li>
                        <li>Complaints can be sent in writing by registered letter at the following address: Al. Armii
                          Krajowej 6B/6 50-541 Wrocław, or by e-mail at the following address: info@fitboar.com
                        </li>
                        <li>A complaint should be explained in detail and include the User’s username and e-mail address
                          used to register the account.
                        </li>
                        <li>Complaints will be reviewed within 14 days from the day of receipt by the Fitboar.pl
                          Service.
                        </li>
                        <li>The Fitboar.pl Service promises to share information about how the complaint was reviewed by
                          e-mail address tied to the User’s account.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Termination of Fitboar.com Service account</h4>
                      <ol>
                        <li>The Fitboar.com Service are provided indefinitely, however the User can close their account
                          at any moment by going through account termination process, which is available within the
                          Service.
                        </li>
                        <li>Termination of Fitboar.com account is fitboar.com also terminates the agreement between the
                          User and the Service.
                        </li>
                        <li>
                          The Fitboar.com Service reserves the right to suspend or close User’s account at any time or
                          delete any contents submitted by the User if:
                          <ol>
                            <li>The User is infringing the Terms of Service or terms accepted by the User.</li>
                            <li>The User has provided information or declarations that are untrue, outdated, incorrect
                              or incomplete, or those belonging to another person.
                            </li>
                            <li>If the Fitboar.com Service is legally obliged to do so.</li>
                          </ol>
                        </li>
                        <li>If the User claims that their account has been erringly suspended or closed, or intends to
                          close or complete delete their account, they should submit the request to the Fitboar.com
                          Service.
                        </li>
                        <li>In circumstances not listed in point 7.3, the Fitboar.com Service is obliged to terminate
                          the agreement with a 14 day notice period.
                        </li>
                        <li>After requesting a termination of User’s account, the Service initiates a procedure that
                          within 24 hours deletes all contents and personal information submitted by the User. Any
                          activity in the Service, such as posts, comments and likes will remain and instead display a
                          permanent message saying that the contents have been deleted. This information will become
                          anonymized, which will prevent identification of the User. The termination procedure is
                          explained on the following webpage: https://fitboar.com/#!/app/contact.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Personal data and privacy in the Fitboar.com Service</h4>
                      <ol>
                        <li>The Fitboar.com Service manages the User’s personal data necessary for implementation of
                          Terms of Service. This data is processed by the Fitboar.com Service to conclude the agreement
                          and to conform to the conditions of the Terms of Service, including the provision of
                          high-quality services.
                        </li>
                        <li>The Fitboar.com Service processes personal information of the Users for other purposes and
                          in scope, which are listed <a href="https://fitboar.com/privacy-policy">here</a>.
                        </li>
                        <li>Within the scope unregulated by these Terms of Service, the User’s personal data and
                          protection of user’s privacy are instead regulated by the Privacy Policy <a
                            href="https://fitboar.com/privacy-policy">here</a>. This document discloses full information
                          required by the GDPR regulation. The User is obliged to fully read the contents of this
                          document and accept its conditions before attempting to register an account in the Service.
                        </li>
                        <li>Fitboar.com Service processes the customer’s data in accordance with the laws and
                          regulations, particularly with the Regulation (UE) 2016/679 of the European Parliament and of
                          the Council of 27 April 2016 on the protection of natural persons with regard to the
                          processing of personal data and on the free movement of such data, and repealing Directive
                          95/46/EC (General Data Protection Regulation) (hereinafter referred to as „GDPR”).
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Changes to Terms of Service</h4>
                      <ol>
                        <li>In case of adjustments of the Fitboar.com Terms of Service, the Users will be informed about
                          this fact within the Service or by e-mail. If the User does not agree with the changes, they
                          can delete their account. This process is explained on this webpage:
                          https://fitboar.com/#!/app/contact
                        </li>
                        <li>If the User continues to use the Service after having read the updated Terms of Service they
                          thereby accept the changes to the Terms of Service.
                        </li>
                        <li>We will inform the users about any changes to the Terms of Service at least 14 days in
                          advance, to give the users sufficient time to read the adjusted terms.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TermsAndConditions;
