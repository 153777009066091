import React from "react";
import {FormattedMessage} from 'react-intl';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer-section">
          <div
            className={"footer-top background-img-2 " + (this.props.noSubscription ? 'py-5' : 'pt-60 pb-5')}
            style={{
              backgroundImage: "url('img/footer-bg.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              backgroundSize: "cover"
            }}
          >
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-3 mb-3 mb-lg-0">
                  {/*<div className="footer-nav-wrap text-white">*/}
                  {/*  <h5 className="mb-3 text-white">Social Media</h5>*/}

                  {/*  <div className="social-list-wrap">*/}
                  {/*    <ul className="social-list list-inline list-unstyled">*/}
                  {/*      <li className="list-inline-item">*/}
                  {/*        <a href="/" target="_blank" title="Facebook">*/}
                  {/*          <span className="ti-facebook"></span>*/}
                  {/*        </a>*/}
                  {/*      </li>*/}
                  {/*      <li className="list-inline-item">*/}
                  {/*        <a href="/" target="_blank" title="Instagram">*/}
                  {/*          <span className="ti-instagram"></span>*/}
                  {/*        </a>*/}
                  {/*      </li>*/}
                  {/*    </ul>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">
                      <FormattedMessage id="footer.links_header"/>
                    </h5>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <a href="/privacy">
                          <FormattedMessage id="footer.privacy_header"/>
                        </a>
                      </li>
                      <li className="mb-2">
                        <a href="/terms"> <FormattedMessage id="footer.terms_and_conditions"/></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
