import React from "react";
import { connect } from "react-redux";
import _data from "../../data";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumbbell, faBook, faUsers, faChartBar, faTh, faEllipsisH, faWeight } from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage} from 'react-intl';

class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: {},
      components: {
        faDumbbell: faDumbbell,
        faBook: faBook,
        faUsers: faUsers,
        faChartBar: faChartBar,
        faTh: faTh,
        faEllipsisH: faEllipsisH,
        faWeight: faWeight,
      }
    };
  }


  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      promo: _data.promo
    });
  }

  /**
   * Get language: first check cookie and if it's empty get language from browser
   */
  getLanguage() {
    const value = "; " + document.cookie;
    const parts = value.split("; language=");

    return parts.length === 2 ? parts.pop().split(";").shift() : navigator.language.split(/[-_]/)[0];
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section ptb-100" id="features">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>
                    <FormattedMessage id="promo.header"/>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row equal">
              {((this.state.promo.items && this.state.promo.items[this.getLanguage()]) || []).map(item => {
                return (
                  <div className="col-md-4 col-lg-4 feature-item" key={item.title}>
                    <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                      <div className="circle-icon mb-5">
                        <span>
                          <FontAwesomeIcon icon={this.state.components[item.fontAwesomeIcon]} className="feature-icon-container feature-icon" />
                        </span>
                      </div>
                      <h5>{item.title}</h5>
                      <p>{item.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(PromoSection);
