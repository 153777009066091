import React from "react";
import _data from "../../data";
import {FormattedMessage} from 'react-intl';

export default class Screenshots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {},
      language: this.getLanguage(),
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      price: _data.price
    });
  }

  /**
   * Get language: first check cookie and if it's empty get language from browser
   */
  getLanguage() {
    const value = "; " + document.cookie;
    const parts = value.split("; language=");

    return parts.length === 2 ? parts.pop().split(";").shift() : navigator.language.split(/[-_]/)[0];
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="screenshots"
          className="screenshots-section ptb-100 gray-light-bg"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center">
                  <h2>
                    <FormattedMessage id="screenshots.header" defaultMessage="App screenshots"/>
                    <br/>
                  </h2>
                </div>
              </div>
            </div>
            <div className="screen-slider-content mt-5">
              <div className="screenshot-frame"></div>
              <div className="screen-carousel owl-carousel owl-theme dot-indicator">
                <img src={`img/screenshots/${this.state.language}/press-log.png`} className="img-fluid" alt="screenshots"/>
                <img src={`img/screenshots/${this.state.language}/workout-view.png`} className="img-fluid" alt="screenshots"/>
                <img src={`img/screenshots/${this.state.language}/wall.png`} className="img-fluid" alt="screenshots"/>
                <img src={`img/screenshots/${this.state.language}/exercises.png`} className="img-fluid" alt="screenshots"/>
                <img src={`img/screenshots/${this.state.language}/calendar.png`} className="img-fluid" alt="screenshots"/>
                <img src={`img/screenshots/${this.state.language}/measurements.png`} className="img-fluid" alt="screenshots"/>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
