import { combineReducers } from 'redux';
import contact from './contact';
import promo from './promo';

import subscribe from './subscribe';

export default combineReducers({
    subscribe,
    contact,
    promo,
});
