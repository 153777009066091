import React, {Component} from "react";
import _data from "../../data";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: {},
      language: this.getCookie('language'),
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      testimonial: _data.testimonial
    });
  }

  /**
   * Get cookie by name
   */
  getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    return parts.length === 2 ? parts.pop().split(";").shift() : navigator.language.split(/[-_]/)[0];
  }

  render() {
    return (
      <React.Fragment>
        <section
          className={"testimonial-section ptb-100 " + (this.props.bgColor && this.props.bgColor === 'gray' ? 'gray-light-bg' : '')}>
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                {this.state.language === 'pl' && <div className="section-heading mb-5">
                  <h1>
                    Polityka Prywatności
                  </h1>
                  <ol>
                    <li>
                      <h4>Administrator danych osobowych</h4>
                      <ol>
                        <li>Administratorem danych osobowych jest IT SERVICES KRYSTIAN JARMOSZKA.
                        </li>
                        <li>Administrator danych osobowych przykłada dużą wagę do ochrony prywatności i poufności danych
                          osobowych użytkowników serwisu fitboar.com dostępnego pod adresem https://fitboar.com (zwanego
                          dalej: „fitboar.com” lub „Serwisem”).
                        </li>
                        <li>
                          Jakie dane przetwarzamy?
                          <ol>
                            <li>Przetwarzamy Twoje dane osobowe niezbędne do świadczenia Ci usług, np. przy zakładaniu
                              konta czy dane zmienione przez Ciebie podczas edycji kont (takie jak: adres e-mail, imię i
                              nazwisko, płeć, datę urodzenia).
                            </li>
                            <li>Przetwarzamy dane udostępniane oraz publikowane dobrowolnie przez Ciebie za pomocą
                              serwisu fitboar.com
                            </li>
                            <li>Przechowujemy oraz przetwarzamy informacje o urządzeniu końcowym lub przeglądarce, w tym
                              jego lokalizację.
                            </li>
                            <li>Zbieramy informacje mówiące nam o częstotliwości używania funkcjonalności w serwisie.
                              Dane te pomagają nam określić, które części aplikacji potrzebują zmian, tak aby użytkownik
                              mógł z nich korzystać lepiej i częściej.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>W jakim celu i na jakiej podstawie przetwarzamy Twoje dane?</h4>
                      <ol>
                        <li>Przetwarzamy Twoje dane w zakresie niezbędnym do świadczenia Ci usług w oparciu o
                          postanowienia naszego regulaminu.
                        </li>
                        <li>W celu zapewnienia bezpieczeństwa naszych usług. (np. sprawdzenie, czy do Twojego konta nie
                          loguje się nieuprawniona osoba), dokonanie pomiarów statystycznych, ulepszanie naszych usług i
                          dopasowanie ich do potrzeb i wygody użytkowników (np. spersonalizowanie treści w usługach) jak
                          również w celu prowadzenia marketingu bezpośredniego (także na zlecenie reklamodawców) lub
                          promocji własnych usług. Takie przetwarzanie danych to realizacja naszych prawnie
                          uzasadnionych interesów.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Jak długo przetwarzamy dane?</h4>
                      <ol>
                        <li>
                          Twoje dane będą przetwarzane do momentu istnienia podstawy do ich przetwarzania tj.:
                          <ol>
                            <li>w przypadku udzielenia zgody na przetwarzanie danych osobowych do momentu jej cofnięcia
                              (na przykład na podstawie zaakceptowania naszej polityki prywatności oraz regulaminu do
                              czasu usunięcia konta w serwisie),
                            </li>
                            <li>w przypadku, gdy podstawą przetwarzania danych jest uzasadniony interes administratora
                              danych osobowych, do czasu zgłoszenia przez Ciebie skutecznego sprzeciwu,
                            </li>
                            <li>w celach podatkowych i rachunkowych w zakresie i przez czas zgodny z obowiązującymi
                              przepisami prawa.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Jakie prawa przysługują naszym Użytkownikom jako podmiotom danych osobowych?</h4>
                      <ol>
                        <li>
                          Zgodnie z przepisami Ogólnego Rozporządzenia dotyczącego Ochrony Danych Osobowych
                          (rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r. w sprawie
                          ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
                          przepływu takich danych oraz uchylenia dyrektywy 95/46/WE – (RODO), przysługują Ci następujące
                          prawa w związku z przetwarzaniem Twoich danych osobowych przez nas, oraz innych Zaufanych
                          Partnerów:
                          <ol>
                            <li>prawo dostępu do Twoich danych,</li>
                            <li>prawo uzyskania kopii Twoich danych,</li>
                            <li>prawo do bycia zapomnianym,</li>
                            <li>prawo do żądania sprostowania danych,</li>
                            <li>prawo wniesienia skargi do organu nadzorczego ochrony danych osobowych, jakim jest w
                              Polsce Prezes Urzędu Ochrony Danych Osobowych,
                            </li>
                            <li>prawo do ograniczenia przetwarzania danych,</li>
                            <li>prawo sprzeciwu wobec marketingu bezpośredniego, w tym profilowania.</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Komu udostępniamy dane?</h4>
                      <ol>
                        <li>Twoje dane mogą być udostępniane podmiotom uprawnionym do ich uzyskania na podstawie
                          obowiązującego prawa np. organom ścigania.
                        </li>
                        <li>Powyższa zgoda dotyczy przetwarzania Twoich danych osobowych w celach marketingowych
                          Zaufanych Partnerów. Zaufani Partnerzy to firmy z obszaru e-commerce i reklamodawcy oraz
                          działające w ich imieniu domy mediowe i podobne organizacje, z którymi serwis fitboar.com
                          współpracuje.
                        </li>
                        <li>
                          Aktualni partnerzy:
                          <ol>
                            <li>Google Analytics</li>
                            <li>Google Cloud</li>
                            <li>Google Adwords</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>

                  <h1>
                    Cookies
                  </h1>

                  <ol>
                    <li>
                      <h4>Czym są pliki cookie?</h4>
                      <p>Pliki cookie to pliki tekstowe zawierające podstawowe informacja pozwalające na rozpoznanie
                        urządzenia i użytkownika.</p>
                    </li>
                    <li>
                      <h4>Do czego wykorzystuje się pliki cookie?</h4>
                      <p>Pliki cookie oraz pozostałe podobne technologie dostarczają informację o tym, czy użytkownik
                        odwiedzał już daną witrynę. Dzięki temu można dostarczać bardziej sprecyzowane treści, usługi
                        lub reklamy oraz ulepszyć korzystanie z serwisu.</p>
                    </li>
                    <li>
                      <h4>Dlaczego fitboar.com korzysta z plików cookie?</h4>
                      <p>Serwis fitboar.com, przy użyciu cookie oraz podobnych technologii, przechowuje informacje
                        pozwalające na ustalenie użytkownika. Dzięki tej wiedzy możemy prezentować zestawy ćwiczeń,
                        treningów oraz innych treści, które zostały przez niego stworzone lub brał w nich udział (np.
                        komentarze we wpisach innych użytkowników). Dodatkowo zbierane przez nas informacje pomagają nam
                        szybciej dostarczać treści.</p>
                    </li>
                    <li>
                      <h4>Jak długo pliki cookie są przechowywane na urządzeniu?</h4>
                      <p>Dane z cookie przechowywane są bezterminowo. Niemniej jednak w większości przypadków pliki te
                        są automatycznie usuwane w momencie, gdy zmieniły się dane, na które te pliki wskazują. W celu
                        zapewnienia należytego bezpieczeństwa naszych użytkowników sesje po zalogowaniu mają określoną
                        żywotność. Ciastko z sesją zostaje usunięte, jeżeli użytkownik zostanie poproszony o ponowne
                        wylogowanie, a jego miejsce zajmie nowo wygenerowane ciastko.</p>
                    </li>

                  </ol>
                </div>}
                {this.state.language === 'en' && <div className="section-heading mb-5">
                  <h1>
                    Privacy Policy
                  </h1>
                  <ol>
                    <li>
                      <h4>Administrator of personal data</h4>
                      <ol>
                        <li>The personal data administrator IT SERVICES KRYSTIAN JARMOSZKA.</li>
                        <li>The personal data administrator exercises all due diligence to protect the personal data and
                          privacy of users of the Fitboar.com Service, operating on the https://fitboar.com domain
                          (hereinafter referred to as: „Fitboar.com” or „Service”).
                        </li>
                        <li>
                          What kind of data are we processing?
                          <ol>
                            <li>We process your personal data that is necessary to provide our
                              services to you, e.g. upon registering the account or the data you provide
                              when editing your account (such as: e-mail address, name and surname, age,
                              gender, date of birth).
                            </li>
                            <li>We process data that are voluntarily submitted and share by you on
                              Fitboar.com Service.
                            </li>
                            <li>We store and process data about your device, it’s location and used
                              web browser.
                            </li>
                            <li>We collect data about how often are specific features of the Service
                              used. They help us determine which features should be improved, so that
                              they are more useful and intuitive.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>Why and for what purposes are we processing your data?</h4>
                      <ol>
                        <li>We are processing your data in a scope that is necessary to provide out services, in
                          accordance with our Terms of Service.
                        </li>
                        <li>In order to ensure security of our services (e.g. to verify, if someone unauthorized is
                          attempting to login to your account), to conduct statistical measurements, to improve our
                          services and adjust them to the needs and comfort of our users (e.g. services with
                          personalized content), as well as for direct marketing (also per advertisers requests) and to
                          promote our own services. These purposes are data processing are tied to our legally justified
                          goals.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>How long is the data processed?</h4>
                      <ol>
                        <li>
                          Your data is processed for as long as it is warranted, i.e.:
                          <ol>
                            <li>In cases where consent is granted to process data, until this consent is withdrawn (for
                              example from the moment our Privacy Policy is accepted, until the user’s account is
                              deleted from the Service),
                            </li>
                            <li>In cases where the basis for data processing is a justified intention of the personal
                              data administrator, until the user submits a well-founded objection.
                            </li>
                            <li>For taxation and accounting purposes in a scope and time period that are pursuant to
                              applicable laws.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>What rights do our users have as personal data subjects?</h4>
                      <ol>
                        <li>
                          Regulation (UE) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the
                          protection of natural persons with regard to the processing of personal data and on the free
                          movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)
                          (hereinafter referred to as „GDPR”).
                          In accordance to the General Data Protection Regulation 2016/679 of the European Parliament
                          and of the Council of 27 April 2016 on the protection of natural persons with regard to the
                          processing of personal data and on the free movement of such data, and repealing Directive
                          95/46/EC (GDPR), you benefit from the following laws with regard to the processing of personal
                          data by us and our Trusted Partners:
                          <ol>
                            <li>Right to access your data,</li>
                            <li>Right to obtain a copy of your data,</li>
                            <li>Right to be forgotten,</li>
                            <li>Right to demand correction of data,</li>
                            <li>Right to rise a complaint to a data protection supervisory authority, which in Poland is
                              held by the President of the Personal Data Protection Office,
                            </li>
                            <li>Right to restrict the processing of data,</li>
                            <li>Right to object direct marketing, including profiling.</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>With whom we share the data?</h4>
                      <ol>
                        <li>Your data can be shared with entities that can lawfully acquire it, e.g. law enforcement.
                        </li>
                        <li>The agreement hereby concerns the processing of your data for marketing purposes of Trusted
                          Partners. Trusted Partners entail e-commerce and advertisement companies and all of their
                          subsidiaries that the Fitboar.com Service works with.
                        </li>
                        <li>
                          Current partners:
                          <ol>
                            <li>Google Analytics</li>
                            <li>Google Cloud</li>
                            <li>Google Adwords</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>

                  <h1>
                    Cookies
                  </h1>

                  <ol>
                    <li>
                      <h4>What are cookies?</h4>
                      <p>Cookie files are text files that contain basic information, which allows identification of the
                        User and their device.</p>
                    </li>
                    <li>
                      <h4>What are the cookies used for?</h4>
                      <p>Cookie files, as well as other similar technologies provide information whether a user has
                        already visited a specific website. This allows delivery of more personalized contents, services
                        and advertisements and increases the performance of a service.</p>
                    </li>
                    <li>
                      <h4>Why Fitboar.com is using cookies?</h4>
                      <p>Cookies and similar technologies allow the Fitboar.com Service to identify users. This
                        information allows us to display sets of exercises, trainings and other contents, which were
                        submitted by the users or interacted with (e.g. interactions with other users submissions).
                        Additionally, information collected by us increase the speed at which we can deliver
                        contents.</p>
                    </li>
                    <li>
                      <h4>How long are cookies stored on a device?</h4>
                      <p>Cookies are stored indefinitely. However, in most cases these files are automatically removed,
                        if data they relate to has been modified. In order to ensure security of our users, upon logging
                        in the session only last for a specific duration. A session and it’s related cookie are deleted,
                        if a user is prompted to log out. This cookie will then be replaced with a new one.</p>
                    </li>
                  </ol>
                </div>}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
