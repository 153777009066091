import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSectionShort from "../components/HeroSection/HeroSectionShort";
import ContactSection from "../components/Contact";
import TermsAndConditions from "../components/TermsAndConditions";
import FooterSection from "../components/Footer";

class Terms extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
          <HeroSectionShort />
          <TermsAndConditions />
          <ContactSection />
        </div>
        <FooterSection />
      </React.Fragment>
    );
  }
}

export default Terms;
