import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSectionShort from "../components/HeroSection/HeroSectionShort";
import ContactSection from "../components/Contact";
import FooterSection from "../components/Footer";
import {FormattedMessage} from 'react-intl';

class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
          <HeroSectionShort />

          <div className="not_found_container">
            <h1><FormattedMessage id="not_found.title"/></h1>
            <span className="not-found_description"><FormattedMessage id="not_found.description"/></span>
          </div>

          <ContactSection />
        </div>
        <FooterSection />
      </React.Fragment>
    );
  }
}

export default NotFound;
