module.exports = {
  hero: {
    bgImage: "media/gym.jpg",
    pl: {
      password_placeholder: 'Hasło',
      login_button_text: 'Zaloguj'
    },
    en: {
      password_placeholder: 'Password',
      login_button_text: 'Log in'
    }
  },
  promo: {
    title: "Co u nas znajdziesz?",
    description:
      "Poniżej znajdują się najważniejsze funkcje:",
    items: {
      pl: [
        {
          title: "Plany treningowe",
          description:
            "Chcesz aby Twój trening był bardziej efektywny? Zaplanuj kolejność ćwiczeń i notuj serie. W naszej bazie odnajdziesz ponad 250 ćwiczeń!",
          fontAwesomeIcon: 'faBook'
        },
        {
          title: "Analiza treningów",
          description:
            "W łatwy i szybki sposób monitoruj postępy!",
          fontAwesomeIcon: 'faChartBar'
        },
        {
          title: "Społeczność",
          description:
            "Podziel się postępami ze zanjomymi. Brakuje Ci motywacji? Odkrywaj wpisy innych użytkowników!",
          fontAwesomeIcon: 'faUsers'
        },
        {
          title: "Pomiary",
          description:
            "Dodawaj pomiary ciała. Nasze zestawienia pozwolą Ci śledzić postęp.",
          fontAwesomeIcon: 'faWeight'
        }
      ],
      en: [
        {
          title: "Workout plans",
          description:
            "Do you want your training to be more effective? Plan the order of exercises and record the series. In our database you will find over 250 exercises!",
          fontAwesomeIcon: 'faBook'
        },
        {
          title: "Training analysis",
          description:
            "Monitor your progress easily and quickly!",
          fontAwesomeIcon: 'faChartBar'
        },
        {
          title: "Community",
          description:
            "Share your progress with friends. Do you lack motivation? Discover the entries of other users!",
          fontAwesomeIcon: 'faUsers'
        },
        {
          title: "Measurements",
          description:
            "Note your body measurements.",
          fontAwesomeIcon: 'faWeight'
        }
      ]
    }
  },
  price: {
    title: "Pricing Packages",
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: {
      pl: [
        {
          price: "0zł",
          license: "Użytkownik podstawowy",
          isFeatured: true,
          features: [
            "Notowanie wykonanych serii",
            "Budowanie planów treningowych",
            "Analiza treningów",
            "Dzielenie się swoimi postępami z innymi",
            "Odkyrwanie innych użytkowników",
          ],
          buyButtonText: 'Zarejestruj się',
          fontAwesomeIcon: 'faUsers'
        },
        {
          // price: "$120",
          license: "Trener personalny",
          isFeatured: false,
          features: [
            "Dostępne już wkrótce!",
          ],
          buyButtonText: false,
          fontAwesomeIcon: 'faUserPlus'
        }
      ],
      en: [
        {
          price: "0zł",
          license: "Regular user",
          isFeatured: true,
          features: [
            "Track exercise sets",
            "Build workout plans",
            "Analyze trainings",
            "Share your progress with friends",
            "Discover users",
          ],
          buyButtonText: 'Sign up',
          fontAwesomeIcon: 'faUsers'
        },
        {
          // price: "$120",
          license: "Personal trainer",
          isFeatured: false,
          features: [
            "Coming soon!",
          ],
          buyButtonText: false,
          fontAwesomeIcon: 'faUserPlus'
        }
      ]
    }
  }
};
