import React from "react";
import { connect } from "react-redux";
import _data from "../../data";

class HeroSectionShort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
    };
  }
  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      hero: _data.hero
    });
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section background-img"
          style={{
            backgroundImage: `url(${this.state.hero.bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            paddingTop: "80px"
          }}>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(HeroSectionShort);
