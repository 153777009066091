import React from "react";
import {FormattedMessage} from "react-intl";

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: this.getLanguage()
    };
  }

  /**
   * Get language: first check cookie and if it's empty get language from browser
   */
  getLanguage() {
    const value = "; " + document.cookie;
    const parts = value.split("; language=");

    return parts.length === 2 ? parts.pop().split(";").shift() : navigator.language.split(/[-_]/)[0];
  }

  render() {
    return (
      <React.Fragment>
        <section id="download" className="download-section pt-100 background-img">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="download-content text-white pb-100">
                  <h2 className="text-white">
                    <FormattedMessage id="download.title" defaultMessage="Download app"/>
                  </h2>

                  <div className="download-btn">
                    <a href="https://apps.apple.com/pl/app/fitboar/id1530749604" className="btn google-play-btn mr-3">
                      <span className="ti-apple"></span> App Store
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=uk.mwsoftware.fitboar" className="btn app-store-btn">
                      <span className="ti-android"></span> Google Play
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-5 col-lg-5 download-phone-frame">
                <div className="hero-animation-img">
                  <div className="screenshot-frame"></div>
                  <img
                    src={`img/screenshots/${this.state.language}/press-log.png`}
                    alt="app"
                    className="img-fluid add-log-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Download;
