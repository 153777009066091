import React from "react";
import {connect} from "react-redux";
import {subscribe} from "../../actions/index";
import _data from "../../data";
import {FormattedMessage} from 'react-intl';

const API_URL = 'https://fitboar.com/api';
// const API_URL = 'https://testing.fitboar.com/api';
// const API_URL = 'http://localhost:8000/api';

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      email: "",
      password: "",
      disableBtn: false,
      loginFailed: false,
      sendingLoginRequest: false,
      language: this.getLanguage()
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    this.redirectToApp();
  }

  redirectToApp() {
    if (window.localStorage && window.localStorage.fitboar) {
      window.location.replace('https://fitboar.com/app/');
    }
  }

  loginAction() {
    this.setState(() => {
      return {sendingLoginRequest: true}
    }, () => {
      return fetch(
        `${API_URL}/login`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: this.state.email, password: this.state.password
          })
        })
        .then(response => response.json())
        .then((response) => {
          this.setState({disableBtn: false});

          if (response.code === 401) {
            this.setState({
              loginFailed: true,
              sendingLoginRequest: false,
            });
            return console.log('bad credentials');
          } else if (response.token) {
            window.localStorage.fitboar = JSON.stringify({
              token: response.token
            });
            window.location.replace('https://fitboar.com/app/');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({disableBtn: true});
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      hero: _data.hero
    });
  }

  /**
   * Get language: first check cookie and if it's empty get language from browser
   */
  getLanguage() {
    const value = "; " + document.cookie;
    const parts = value.split("; language=");

    return parts.length === 2 ? parts.pop().split(";").shift() : navigator.language.split(/[-_]/)[0];
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section pt-100 background-img"
          style={{
            backgroundImage: `url(${this.state.hero.bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover"
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between py-5">
              <div className="col-md-7 col-lg-6 hero-left-section">
                <div className="hero-content-left text-white">
                  <h1 className="text-white">
                    <FormattedMessage id="hero.header"/>
                  </h1>
                  <p className="lead">
                    <FormattedMessage id="hero.description"/>
                  </p>

                  <form method="post" className="subscribe-form" onSubmit={this.handleSubmit}>
                    <div className="d-flex align-items-center login-input-container">
                      <input
                        type="email"
                        className="form-control input"
                        id="email"
                        name="email"
                        placeholder="Email"
                        required="required"
                        value={this.state.email}
                        onChange={e => this.handleFormValueChange("email", e)}
                      />
                    </div>

                    <div className="d-flex align-items-center login-input-container">
                      <input
                        type="password"
                        className="form-control input"
                        id="password"
                        name="password"
                        placeholder={this.state.hero[this.state.language] && this.state.hero[this.state.language].password_placeholder}
                        required="required"
                        value={this.state.password}
                        onChange={e => this.handleFormValueChange("password", e)}
                      />
                      {this.state.sendingLoginRequest && <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>}

                      {!this.state.sendingLoginRequest && <input
                        type="submit"
                        className="button btn solid-btn login-button"
                        id="submit"
                        value={this.state.hero[this.state.language] && this.state.hero[this.state.language].login_button_text}
                        disabled={this.state.disableBtn}
                        onClick={() => {
                          this.loginAction();
                        }}
                      />}
                    </div>

                    <div className="link_and_errors_section">
                      {this.state.loginFailed && <span className="bad-credentials">
                        <FormattedMessage id="hero.bad_credentials"/>
                      </span>}

                      <div className="forgot_password_container">
                        <a href="https://fitboar.com/sign-up"><FormattedMessage id="hero.forgot_password"/></a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-5 col-lg-5">
                <div className="hero-animation-img">
                  <div className="screenshot-frame"></div>
                  <img
                    src={`img/screenshots/${this.state.language}/press-log.png`}
                    alt="app"
                    className="img-fluid add-log-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-img-absolute">
            <img
              src="img/hero-bg-shape-1.svg"
              alt="wave shape"
              className="img-fluid"
            />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(HeroSection);
