import React, {Component} from "react";
import {connect} from "react-redux";
import {submitContact} from "../../actions/index";
import {FormattedMessage} from 'react-intl';
import ReCAPTCHA from "react-google-recaptcha";

import _data from "../../data";

const API_URL = 'https://fitboar.com/api';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      message: '',
      recaptchaToken: '',
      disableContactBtn: false,
      contact: {},
      language: this.getCookie('language'),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onChange = value => {
    const that = this;

    return new Promise(function(resolve, reject) {
      that.setState({
        recaptchaToken: value
      });
      resolve();
    });
  };

  /**
   * Get cookie by name
   */
  getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    return parts.length === 2 ? parts.pop().split(";").shift() : navigator.language.split(/[-_]/)[0];
  }

  /**
   * Send message to contact endpoint
   */
  submitForm = () => {
    return fetch(
      `${API_URL}/contact`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.state.email, message: this.state.message, language: this.state.language, recaptcha_token: this.state.recaptchaToken
        })
      })
      .then(response => response.json())
      .then((response) => {
        const submitButton = document.getElementById('contact-submit-container');
        const messageContainer = document.getElementById('contact-message-container');

        submitButton.style.display = 'none';
        messageContainer.style.display = 'block';

        setTimeout(function () {
          messageContainer.style.display = 'none';
          submitButton.style.display = 'block';
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * @param inputName
   * @param event
   */
  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  /**
   * Submit the form and dispatch to the store
   */
  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({disableContactBtn: true});

    // get action
    const contactAction = submitContact(this.state);

    // Dispatch the contact from data
    this.props.dispatch(contactAction);

    // added delay to change button text to previous
    setTimeout(
      function () {
        // enable the button
        this.setState({disableContactBtn: false});

        // get action again to update state
        const contactAction = submitContact(this.state);

        // Dispatch the contact from data
        this.props.dispatch(contactAction);

        // clear form data
        this.setState({
          email: "",
          message: ""
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      contact: _data.contact
    });
  }

  render() {
    return (
      <React.Fragment>

        <section id="contact"
                 className={"contact-us ptb-100 " + (this.props.bgColor && this.props.bgColor === 'white' ? '' : 'gray-light-bg')}>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center">
                  <h2>
                    <FormattedMessage id="contact.title" defaultMessage="Contact us"/>
                    <br/>
                  </h2>

                  <p className="lead">
                    <FormattedMessage id="contact.description"/>
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <form
                method="POST"
                id="contactForm1"
                className="contact-us-form col-12 col-sm-8"
                onSubmit={this.handleSubmit}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        value={this.state.email}
                        onChange={e => this.handleFormValueChange("email", e)}
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Enter email"
                        required="required"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                        <textarea
                          onChange={e =>
                            this.handleFormValueChange("message", e)
                          }
                          value={this.state.message}
                          name="message"
                          id="message"
                          className="form-control"
                          rows="7"
                          cols="25"
                          placeholder="Message"
                        />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="captcha-container">
                    <ReCAPTCHA
                      sitekey="6LfH-NEUAAAAADQctp0KYBqH84LbBn_080fiwcMp"
                      onChange={this.onChange}
                    />
                  </div>

                  <div className="col-12 contact-submit-container" id="contact-submit-container">
                    <button
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"
                      disabled={this.state.disableContactBtn}
                      onClick={() => {
                        this.submitForm("Sending...");
                      }}
                    >
                      <FormattedMessage id="contact.send_button" defaultMessage="Send"/>
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 contact-message-container" id="contact-message-container">
                      <span className="justify-content-center">
                        <FormattedMessage id="contact.message_sent"/>
                      </span>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(Contact);
